.App {
  padding: 1.4rem;
}
.App-link {
  color: #61dafb;
}
.container{margin-left:auto;margin-right:auto}
.teamContainer {
  display: flex;
  flex-direction: row;
  margin-left:auto;margin-right:auto;
  
}
#players {
  padding-left: 0;
  background-color: #fff;
  padding: 1rem .5rem;
  border-radius: 7px;
  border: 2px solid #d0d0d0;
  min-width: 13%;
  max-width:220px;
  transition: background-color 0.2s ease-in-out;
  margin-right: 0.3rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: fit-content;
}
@media only screen and (max-width: 500px) {
  .App{padding:0.4rem}
  #players{min-width:23.5%;padding:0.5rem 0.2rem}
  .Field{display:none}
}

#player-container{
    height: 100%;
}
#player-container.isDraggingOver {
  background-color: #d7f2d7;
}

#players #player {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px #d0d0d0;
  border-radius: 0.2em;
  padding: 0.5em 0.8em 0.5em 0.5em;
  margin-bottom: .22em;
  background-color: aliceblue;
}
#players #player.dragging {
  background-color: rgba(176, 176, 189, 0.473);
}

#players p {
  max-width: none;
  font-weight: bold;
  margin: 0;
}

.players-thumb {
  overflow: hidden;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  background-color: #e8e8e8;
  padding: 0.5em;
  margin-right: 0.5em;
}

.players-thumb img {
  display: block;
  width: 100%;
  height: auto;
}
.btn{padding: 0.5em;font-weight: bolder;color: #fff;cursor: pointer;margin:0.2rem;border-radius:4px;transition: all 0.3s}
.btn-save{background-color: #50d744;border:1px solid #50d744;}
.btn-save:hover{background-color: #3eaf34;}
.btn-draft{background-color: #281cb2;border:1px solid #050e41;}
.btn-draft:hover{background-color: #2f2786;}
.field-container{background:url('/public/Field.png');width:100%;min-height:610px;background-repeat:no-repeat;background-size: contain;display: flex;flex-direction: row;}
.player-ball{border-radius:50%; border:2px solid #948d8d;width:20px;height:20px;}
.teamfield{width:50%; height:100%;display: inline-block;position: relative;}
.teamfield .team-name{text-align:center;margin: 0;}
.player-position .name{font-weight: 700;}